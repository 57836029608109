import React from 'react';
import { BUTTON_STATES, StatesButton } from 'wix-ui-tpa/StatesButton';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Dialog as DialogComponent } from 'wix-ui-tpa/Dialog';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import {
  DialogState,
  DialogViewModel,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { DataHooks } from './constants';
import { classes } from './Dialog.st.css';
import { Spinner } from 'wix-ui-tpa/Spinner';

export type DialogProps = {
  viewModel: DialogViewModel;
};

const Dialog: React.FC<DialogProps> = ({ viewModel }) => {
  const { onDialogClose, onDialogConfirm } = useCalendarActions();
  const {
    state,
    confirmButtonText,
    contentText,
    titleText,
    isOpen,
  } = viewModel;

  const buttonState: BUTTON_STATES =
    state === DialogState.IDLE ? BUTTON_STATES.IDLE : BUTTON_STATES.IN_PROGRESS;
  return (
    <DialogComponent
      data-hook={DataHooks.Root}
      isOpen={isOpen}
      onClose={() => onDialogClose()}
      className={classes.root}
    >
      <Text
        className={classes.title}
        data-hook={DataHooks.Title}
        typography={TYPOGRAPHY.largeTitle}
      >
        {titleText}
      </Text>
      <Text
        className={classes.content}
        data-hook={DataHooks.Content}
        typography={TYPOGRAPHY.listText}
      >
        {contentText}
      </Text>
      <StatesButton
        data-hook={DataHooks.Button}
        className={classes.button}
        onClick={() => onDialogConfirm()}
        state={buttonState}
        idleContent={
          <Text className={classes.buttonText} typography={TYPOGRAPHY.listText}>
            {confirmButtonText}
          </Text>
        }
        inProgressContent={
          <Spinner className={classes.spinner} diameter={24} />
        }
      />
    </DialogComponent>
  );
};

export default Dialog;
