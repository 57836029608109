export const getLocalTimezone = (): string => {
  return new Intl.DateTimeFormat().resolvedOptions().timeZone;
};

// example - return 2020-10-05T00:00:00
export const getLocalDateTimeStartOfDay = (date: Date) => {
  const addZero = (num: number) => (num < 10 ? `0${num}` : `${num}`);
  return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
    date.getDate(),
  )}T00:00:00`;
};

export const getDateTimeFromLocalDateTime = (localDateTime: string) => {
  return new Date(localDateTime);
};

export const formtLocalDateTimeToDateView = (
  localDateTime: string,
  locale?: string,
) => {
  const date = new Date(localDateTime);
  const formatOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    weekday: 'long',
    month: 'long',
  };
  return formatter(date, formatOptions, locale);
};

export const formatRfcTimeStringToTimeSlotView = (
  rfcTime: string,
  locale?: string,
) => {
  const date = new Date(convertRfcTimeToLocalDateTime(rfcTime));
  const formatOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  return formatter(date, formatOptions, locale).toLowerCase();
};

export const formatShortDate = (
  dateString: string,
  locale?: string,
  timezone?: string,
) => {
  return formatter(
    new Date(dateString),
    {
      month: 'short',
      day: 'numeric',
      timeZone: timezone,
    } as Intl.DateTimeFormatOptions,
    locale,
  );
};

export const formatShortTime = (
  dateString: string,
  locale?: string,
  timezone?: string,
) => {
  return formatter(
    new Date(dateString),
    {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: timezone,
    } as Intl.DateTimeFormatOptions,
    locale,
  );
};

export const formatRfcTimeStringToDateAndTimeView = (
  rfcTime: string,
  locale: string,
) => {
  const formattedDate = formatRfcTimeToDateView({
    rfcTime,
    locale,
  });
  const formattedTime = formatRfcTimeStringToTimeSlotView(rfcTime, locale);
  return `${formattedDate}, ${formattedTime}`;
};

const formatRfcTimeToDateView = ({
  rfcTime,
  locale,
}: {
  rfcTime: string;
  locale?: string;
}) => {
  const date = new Date(convertRfcTimeToLocalDateTime(rfcTime));
  const formatOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  };
  return formatter(date, formatOptions, locale);
};

const convertRfcTimeToLocalDateTime = (rfcTime: string) =>
  rfcTime.substr(0, rfcTime.indexOf('.'));

const formatter = (
  date: Date,
  formatOptions: Intl.DateTimeFormatOptions,
  locale?: string,
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(locale, formatOptions);
  return dateTimeFormat.format(date);
};

export const minutesDifferencesBetweenTwoDates = (
  rfcStartTime: string,
  rfcEndTime: string,
) => {
  const secondsInDay = 86400;
  const minutesInHour = 60;
  const hoursInDay = 24;
  const delta =
    Math.abs(
      new Date(rfcStartTime).getTime() - new Date(rfcEndTime).getTime(),
    ) / 1000;

  const days = Math.floor(delta / secondsInDay);
  const hours =
    Math.floor(delta / (minutesInHour * minutesInHour)) % hoursInDay;
  const minutes = Math.floor(delta / minutesInHour) % minutesInHour;

  return minutes + hours * minutesInHour + days * hoursInDay;
};

export function formatTimezone(
  localDateTime: string,
  timezone: string,
  locale: string,
): string {
  const offset =
    Intl.DateTimeFormat(locale, {
      year: 'numeric',
      timeZoneName: 'short',
      timeZone: timezone,
    })
      .formatToParts(new Date(localDateTime.slice(0, 19)))
      .find((part) => part.type === 'timeZoneName')?.value ?? '';
  const timezoneName =
    Intl.DateTimeFormat(locale, {
      year: 'numeric',
      timeZoneName: 'long',
      timeZone: timezone,
    })
      .formatToParts(new Date(localDateTime.slice(0, 19)))
      .find((part) => part.type === 'timeZoneName')?.value ?? '';
  return `${timezoneName} (${offset})`;
}

export function getTodayLocalDateTimeStartOfDay(timezone: string): string {
  const dateTimeFormat = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timezone,
  });
  const dateString = dateTimeFormat.format(new Date());
  const shiftedDate = new Date(dateString);

  return getLocalDateTimeStartOfDay(shiftedDate);
}

export function getEndOfDay(localDateTime: string) {
  const localDate = localDateTime.substr(0, localDateTime.indexOf('T'));
  return `${localDate}T23:59:59.000Z`;
}
